import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { GeneralService } from 'app/_services/general.service';
import { NotificationService } from 'app/_services/notification.service';
import { SettingsService } from 'app/_services/settings.service';
import { PartManagerRelatorioFinanceiroService } from './relatorio-financeiro.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { Globals } from 'app/_models/globals';
import { NotaFiscal, PedidoNotaFiscal } from 'app/_componentes/notafiscal/_models/pedidonotafiscal';
import { SweetAlertParams } from 'app/_models/notificationParams.model';

declare const $: any;
declare const isEmpty: any;
declare const numberToPtBr: any;
declare const numberToReal: any;
declare const obterNomeMesAno: any;

@Component({ selector: 'app-relatorio-financeiro', templateUrl: './relatorio-financeiro.component.html', styleUrls: ['./relatorio-financeiro.component.scss'] })
export class PartManagerRelatorioFinanceiroComponent implements OnInit
{
    @ViewChild('templateNotasFiscais') templateNotasFiscais: TemplateRef<any>;
    modalNFRef: BsModalRef;

    lista: any;
    lista_participando: any;
    lista_pecas_selecionadas: number[];
    pedido_notas: PedidoNotaFiscal;
    mes_selecionado: number;
    meses: any;
    payment_type: number;
    periodo_selecionado: string;
    tem_cashback: boolean;

    custo_por_cotacao: number;
    custo_comissao_total: number;
    custo_pelo_pedido_total: number;
    custo_total: number;
    mensalidade: number;
    reembolso: number;
    repasse_total: number;
    total_oportunidades: number;
    valor_pedido_total: number;

    constructor(
        private generalService: GeneralService,
        private notificationService: NotificationService,
        private relatorioService: PartManagerRelatorioFinanceiroService,
        private settingService: SettingsService,
        private globals: Globals,
        private modalService: BsModalService,
        private ngxLoader: NgxUiLoaderService) {
        this.lista = [];
        this.lista_participando = [];
        this.lista_pecas_selecionadas = [];
        this.pedido_notas = { can_upload_nf: false, notasFiscais: [], quotation_id: 0 };
        this.mes_selecionado = null;
        this.meses = [];
        this.payment_type = 3;
        this.periodo_selecionado = '';
        this.tem_cashback = true;

        this.custo_por_cotacao = 2;
        this.custo_comissao_total = 0;
        this.custo_pelo_pedido_total = 0;
        this.custo_total = 0;
        this.mensalidade = 0;
        this.reembolso = 0;
        this.repasse_total = 0;
        this.total_oportunidades = 0;
        this.valor_pedido_total = 0;
    }

    ngOnInit(): void {
        this.carregarDadosHolding(0);
        this.carregarRelatorio(0);
        this.listarMeses(0);

        window.onafterprint = function () {
            $('html,body').css('height', 'auto')
        }
    }

    abrirMaisDetalhes(item) {
        console.log(item);
        var imposto = item.taxa_fornecedor;
        var diferenca = item.valor_pedido - item.valor_pedido_desconto_original;
        var valor_imposto = diferenca * item.taxa_fornecedor / 100;
        var repasse = this.calcularRepasse(item);

        //var lucro_extra = parseFloat((recebeu_mais - repasse).toFixed(2));

        var html = "<div class='col-md-12 text-start relatorio-financeiro-detalhes'>";

        if (diferenca > 0) {
            html += "<fieldset class='mb0'><legend>" + this.globals.translate('part-manager.relatorio-financeiro.lista.detalhes.legend.cashback') + "</legend>";
            html += "<div><b>" + this.globals.translate('part-manager.relatorio-financeiro.lista.detalhes.valor-peca') + "</b> " + numberToReal(item.valor_pedido_desconto_original) + "</div>";
            html += "<div><b>" + this.globals.translate('part-manager.relatorio-financeiro.lista.detalhes.valor-pago') + "</b> " + numberToReal(item.valor_pedido) + "</div>";
            html += "<div><b>" + this.globals.translate('part-manager.relatorio-financeiro.lista.detalhes.diferenca') + "</b> " + numberToReal(diferenca) + "</div>";
            html += "<hr />";
            html += "<div class=\"provisao\">";
            html += "<i style='margin-right: 5px; cursor: pointer;' class='fa fa-question-circle' title='" + this.globals.translate('part-manager.relatorio-financeiro.lista.detalhes.imposto-info') + "'></i>";
            html += "<b>" + this.globals.translate('part-manager.relatorio-financeiro.lista.detalhes.imposto') + "<small> (" + numberToPtBr(imposto) + "%)</small>:</b> " + numberToReal(valor_imposto);
            html += "</div>";
            html += "<div class=\"provisao\"><small> (" + numberToReal(diferenca) + " x " + numberToPtBr(imposto) + "% = " + numberToReal(valor_imposto) + ")</small></div>";
            html += "<hr />";
            html += "<div><b>" + this.globals.translate('part-manager.relatorio-financeiro.lista.detalhes.repasse') + "</b> " + numberToReal(repasse) + "</div>";

            html += "<br />";
            html += "<div class='justify-content-center'><small>" + this.globals.translate('part-manager.relatorio-financeiro.lista.detalhes.mensagem-consolidada.p1') + "<b>" + numberToReal(diferenca) + "</b>" + this.globals.translate('part-manager.relatorio-financeiro.lista.detalhes.mensagem-consolidada.p2') + "<b>" + numberToReal(repasse) + "</b>" + "</small></div>";

            //if (lucro_extra > 0.05)
            //{
            //    html += "<div><b>" + this.globals.translate('part-manager.relatorio-financeiro.lista.detalhes.lucro-extra') + "</b> " + numberToReal(lucro_extra) + "</div>";
            //}

            html += "</fieldset>";
        } else {
            html += "<div><b>" + this.globals.translate('part-manager.relatorio-financeiro.lista.detalhes.legend.rebate') + "</b> " + numberToReal(repasse) + "</div>";
        }

        html += "</div>";

        let params: SweetAlertParams = {
            title: item.veiculo,
            notTranslateTitle: true,
            message: html,
            isHtml: true,
            icon: 'warning'
        }

        this.notificationService.showInfoWithoutButtons(params)
    }

    calcularRepasse(item) {
        var repasse = !item.tem_cashback ? 0 : item.cashback_comprador + item.cashback_partsfy;

        return repasse;
    }

    carregarDadosHolding(nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.relatorioService.carregarDadosHolding().subscribe({
            next: (response: ApiResponseModel<{ custoCotacao: number, tipoPedido: number, mensalidade: number }>) => {
                if (response && response.success) {
                    this.custo_por_cotacao = response.result.custoCotacao;
                    this.payment_type = response.result.tipoPedido;
                    this.mensalidade = response.result.mensalidade;
                }
            },
            error: (error: any) => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.relatorio-financeiro.mensagens.erro.carregamento-dados-holding.corpo'));
                    this.ngxLoader.stopLoader('loader-principal');
                    return;
                }

                this.carregarDadosHolding(++nAttempts);
            }
        });
    }

    carregarRelatorio(nAttempts: number)
    {
        this.ngxLoader.startLoader('loader-principal');

        this.relatorioService.carregarRelatorio(this.mes_selecionado?.toString()).subscribe({
            next: (response: ApiResponseModel<{ lista: any, reembolso: number}>) => {
                if (response && response.success) {
                    this.lista_participando = response.result.lista;
                    this.reembolso = response.result.reembolso;

                    this.definirRebate();
                    this.setTotals();
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.relatorio-financeiro.mensagens.erro.carregamento-relatorio.corpo'));
                    return;
                }

                this.carregarRelatorio(++nAttempts);
            }
        });
    }

    contestacao_Enviar(pedido, motivo: string, nAttempts: number)
    {
        let contestacaoInicial = pedido.contestacao;
        let idsPecasSelecionadas = this.lista_pecas_selecionadas;
        let pecasSelecionadas = idsPecasSelecionadas.map(item_id => {
            let peca = pedido.pecas.find(p => p.item_id == item_id);
            peca.contestacao_aprovada = 0;
            return { item_id: peca.item_id, valor_cashback: peca.valor_cashback, valor_comissao: peca.valor_comissao, valor_pedido: peca.valor_pedido };
        });

        pedido.contestacao = 1;

        this.relatorioService.contestar(pecasSelecionadas, motivo, this.mes_selecionado).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (response && response.success) {
                    this.notificationService.showSuccessToastr(this.globals.translate('part-manager.relatorio-financeiro.mensagens.sucesso.contestar.corpo'));
                }
            },
            error: error =>
            {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5)
                {
                    pedido.contestacao = contestacaoInicial;
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.relatorio-financeiro.mensagens.erro.contestar.corpo'));
                    return;
                }

                this.contestacao_Enviar(pedido, motivo, ++nAttempts);
            }
        });
    }

    contestacao_ListarPecasSelecionadas() {
        let checkboxes = $('[name="rf-peca"]');
        let pecasSelecionadas = [];

        for (var i = 0, n = checkboxes.length; i < n; i++) {
            let check = $(checkboxes[i]);

            if (check.is(':checked')) {
                let item_id = check.attr('itemId');
                pecasSelecionadas.push(item_id);
            }
        }

        return pecasSelecionadas;
    }

    contestacao_VerificarPecaSelecionada() {
        return this.contestacao_ListarPecasSelecionadas().length > 0;
    }

    definirRebate() {
        this.lista_participando.forEach(item => {
            item.tem_rebate_extra = item.rebate_percentage > 0;
            item.comissao_extra = item.rebate_percentage;
        });
    }

    async exibirPecas(pedido) {
        let hoje = new Date();
        let primeiroDiaMesPassado = new Date(hoje.getFullYear(), hoje.getMonth() - 1, 1);

        let ano_mes_selec = (isEmpty(this.mes_selecionado) ? this.meses[0].ano_mes : this.mes_selecionado);

        let ano_selec = ano_mes_selec.toString().substring(0, 4);
        let mes_selec = ano_mes_selec.toString().substring(4, 6);

        let periodo_selec = new Date(parseInt(ano_selec), parseInt(mes_selec), 0);
        let mes_permitido = primeiroDiaMesPassado <= periodo_selec;

        this.lista_pecas_selecionadas = [];
        let podeContestar = pedido.pecas.some(p => p.contestacao_aprovada === null) && mes_permitido; // mes atual ou passado
        let verColunaContestacao = pedido.contestacao > 0 || podeContestar;
        let html = "";

        html += "<div class='col-md-12 text-start veiculo-pecas'>";

        html += '<div class="d-flex flex-wrap cabecalho mb10"><label>' + this.globals.translate('part-manager.relatorio-financeiro.lista.pecas.selecione') + '</label></div>';

        html += '<section class="d-flex flex-wrap">';
        html += '<header class="linha">';
        html += '<div class="col-3 table-cell"><div class="span">' + this.globals.translate('part-manager.relatorio-financeiro.lista.pecas.peca') + '</div></div>';
        html += '<div class="col table-cell text-center"><div class="span">' + this.globals.translate('part-manager.relatorio-financeiro.lista.pecas.valor-pedido') + '</div></div>';
        html += '<div class="col table-cell text-center"><div class="span">' + this.globals.translate('part-manager.relatorio-financeiro.lista.pecas.valor-comissao') + '</div></div>';
        html += '<div class="col table-cell text-center"><div class="span">' + this.globals.translate('part-manager.relatorio-financeiro.lista.pecas.valor-cashback') + '</div></div>';

        if (verColunaContestacao) {
            html += `<div class="col table-cell d-flex align-items-center justify-content-center ${podeContestar ? 'with-checkbox' : ''}">`;

            if (podeContestar) {
                html += '<div class="form-check c-checkbox needsclick checkboxcot"><label class="needsclick"><input type="checkbox" class="needsclick" onClick=\'toggleCheckAll(this, "rf-peca");\'><span class="fa fa-check"></span></label></div>';
            }

            html += '</div>';
        }

        html += '</header>';

        html += '<div class="corpo">';

        for (var i = 0; i <= pedido.pecas.length - 1; i++) {
            let peca = pedido.pecas[i];

            html += '<div class="linha">';

            html += '<div class="col-3 table-cell pt10 truncate">' + peca.peca + '</div>';
            html += '<div class="col table-cell text-end"><div class="span">' + peca.valor_pedido_formatado + '</div></div>';
            html += '<div class="col table-cell text-end"><div class="span">' + peca.valor_comissao_formatado + '</div></div>';
            html += '<div class="col table-cell text-end"><div class="span">' + peca.valor_cashback_formatado + '</div></div>';

            if (verColunaContestacao) {
                html += `<div class="col table-cell d-flex align-items-center justify-content-center ${podeContestar ? 'with-checkbox' : ''}">`;

                if (peca.contestacao_aprovada === null && podeContestar)
                    html += '<div class="form-check c-checkbox needsclick checkboxcot"><label class="needsclick"><input type="checkbox" name="rf-peca" class="needsclick peca_contestacao" itemId="' + peca.item_id + '"><span class="fa fa-check"></span></label></div>';
                else if (peca.contestacao_aprovada === 1)
                    html += '<div class="span">' + this.globals.translate('part-manager.relatorio-financeiro.lista.pecas.status.aprovada') + '</div>';
                else if (peca.contestacao_aprovada === 0)
                    html += '<div class="span">' + this.globals.translate('part-manager.relatorio-financeiro.lista.pecas.status.aguardando') + '</div>';

                html += '</div>';
            }

            html += '</div>';
        }

        html += '</div>';

        html += '</section>';

        let params: SweetAlertParams = {
            title: (this.settingService.isMercadoPartsfy(pedido.veiculo) ? '' : '#' + pedido.order_id + ' - ') + pedido.veiculo,
            notTranslateTitle: true,
            customClass: { input: 'swal-hidden' },
            showConfirmButton: podeContestar,
            cancelButtonText: 'elemento.generico-botao.fechar',
            confirmButtonText: 'part-manager.relatorio-financeiro.botao.contestar',
            message: html,
            isHtml: true,
            bigSwall: true,
            footer: '<div class="row"><i class="fa fa-exclamation-triangle text-danger"></i> <label>' + this.globals.translate('part-manager.relatorio-financeiro.lista.pecas.alerta') + '</label></div>',
            input: 'textarea',
            inputValidator: () => {
                if (!this.contestacao_VerificarPecaSelecionada()) {
                    return this.globals.translate('part-manager.relatorio-financeiro.lista.pecas.obrigatorio.peca');
                }
            }
        }

        this.notificationService.showConfirm(params).then((result) => {
            if (result.isConfirmed) {
                this.lista_pecas_selecionadas = this.contestacao_ListarPecasSelecionadas();

                let paramsMotivo: SweetAlertParams = {
                    title: 'part-manager.relatorio-financeiro.lista.pecas.defina-motivo',
                    input: 'textarea',
                    inputValidator: (value) => {
                        if (!value) {
                            return this.globals.translate('part-manager.relatorio-financeiro.lista.pecas.obrigatorio.motivo');
                        }
                    }
                }

                this.notificationService.showConfirm(paramsMotivo).then((result) => {
                    let motivo = $('.swal2-textarea').val();

                    if (result.isDismissed || isEmpty(motivo))
                        return;

                    this.contestacao_Enviar(pedido, motivo, 0);
                });
            }
        });
    }

    exportarCSV() {
        if (!this.lista_participando || this.lista_participando.length === 0)
            return;

        let payment_type = this.payment_type;
        let tem_cashback = this.tem_cashback;

        const cabecalhoCotacaoPedido = (payment_type !== 2) ? 'part-manager.relatorio-financeiro.lista.cabecalho.pedido' : 'part-manager.relatorio-financeiro.lista.cabecalho.cotacao';

        let separador = ';';
        let csvString =
            '#' + separador +
            this.globals.translate(cabecalhoCotacaoPedido).toString().removeAccent() + separador +
            this.globals.translate('part-manager.relatorio-financeiro.lista.cabecalho.data').toString().removeAccent() + separador +
            this.globals.translate('part-manager.relatorio-financeiro.lista.cabecalho.oficina').toString().removeAccent() + separador +
            this.globals.translate('part-manager.relatorio-financeiro.lista.cabecalho.cnpj').toString().removeAccent() + separador +
            this.globals.translate('part-manager.relatorio-financeiro.lista.cabecalho.veiculo').toString().removeAccent();

        if (payment_type !== 2)
            csvString += separador + this.globals.translate('part-manager.relatorio-financeiro.lista.cabecalho.valor-pedido').toString().removeAccent();

        if (payment_type === 1)
            csvString += separador + this.globals.translate('part-manager.relatorio-financeiro.lista.cabecalho.custo-comissao').toString().removeAccent() + ' (' + this.custo_por_cotacao + '%)';

        if (payment_type > 2)
            csvString += separador + this.globals.translate('part-manager.relatorio-financeiro.lista.cabecalho.custo-pedido').toString().removeAccent();

        if (tem_cashback)
            csvString += separador + this.globals.translate('part-manager.relatorio-financeiro.lista.cabecalho.custo-cashback.titulo').toString().removeAccent();

        csvString += '\n';

        $.each(this.lista_participando, function (i, item) {
            csvString +=
                (i + 1) + separador +
                (item.order_id) + separador +
                (item.data_formatada) + separador +
                (item.oficina.removeAccent()) + separador +
                (item.cnpj_formatado) + separador +
                (item.veiculo.removeAccent());

            if (payment_type !== 2)
                csvString += separador + (item.valor_pedido_formatado);

            if (payment_type === 1)
                csvString += separador + (item.custo_comissao_formatado);

            if (payment_type > 2)
                csvString += separador + (item.custo_pelo_pedido_formatado);

            if (tem_cashback && item.repasse > 0)
                csvString += separador + ('R$' + numberToPtBr(item.repasse));

            csvString += '\n';
        });

        let filename = "relatorio_" + (isEmpty(this.mes_selecionado) ? this.meses[0].ano_mes : this.mes_selecionado) + ".csv";

        var a = $('<a/>', { style: 'display:none', href: 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString), download: filename }).appendTo('body');
        a[0].click();
        a.remove();
    }

    listarMeses(nAttempts: number) {
        this.relatorioService.listarMeses().subscribe({
            next: (response: ApiResponseModel<{ months: { month: number, year: number }[]}>) => {
                if (response && response.success) {
                    if (isEmpty(response.result))
                        return;

                    this.meses = response.result.months.map(m => {
                        let ano_mes = parseInt(m.year.toString() + m.month.toString().padStart(2, '0'));
                        return { ano_mes: ano_mes, nome: obterNomeMesAno(ano_mes) };
                    });

                    if (this.meses.length > 0 && isEmpty(this.periodo_selecionado))
                        this.periodo_selecionado = this.meses[0].nome;
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.relatorio-financeiro.mensagens.erro.carregamento-meses.corpo'));
                    return;
                }

                this.listarMeses(++nAttempts);
            }
        });
    }

    imprimir($event) {
        this.ngxLoader.startLoader('loader-principal');
        $event.preventDefault();

        if (!this.lista_participando || this.lista_participando.length === 0)
            return;

        let payment_type = this.payment_type;
        let tem_cashback = this.tem_cashback;

        let jsonData = [];
        const headers = this.imprimirGetHeaders();

        $.each(this.lista_participando, function (i, item) {
            let row = { };
            row['#'] = (i + 1);
            row[headers.cabecalhoPedido] = (item.order_id);
            row[headers.cabecalhoData] = (item.data_formatada);
            row[headers.cabecalhoOficina] = (item.oficina.removeAccent());
            row[headers.cabecalhoCnpj] = (item.cnpj_formatado);
            row[headers.cabecalhoVeiculo] = (item.veiculo.removeAccent());

            if (payment_type !== 2)
                row[headers.cabecalhoValorPedido] = (item.valor_pedido_formatado);

            if (payment_type === 1)
                row[headers.cabecalhoCustoComissao] = (item.custo_comissao_formatado);

            if (payment_type > 2)
                row[headers.cabecalhoCustoPedido] = (item.custo_pelo_pedido_formatado);

            if (tem_cashback)
                row[headers.cabecalhoCustoCashback] = item.repasse > 0 ? ('R$' + numberToPtBr(item.repasse)) : 'R$ 0,00';

            jsonData.push(row);
        });

        const footer = this.imprimirGetFooter(payment_type, tem_cashback);
        jsonData.push(footer);

        let printPageData = {
            type: 'print-rel-financeiro',
            header: $('.app-header > div:not(.no-print)')[0].outerHTML,
            data: jsonData,
            footer: $('.sumario')[0].outerHTML
        };

        this.generalService.printPageData = printPageData;

        $('#printView').attr('src', '/imprimir');
        this.ngxLoader.stopLoader('loader-principal');
    }

    imprimirGetFooter(payment_type: number, tem_cashback: boolean) {
        const headers = this.imprimirGetHeaders();

        let row = { };
        row['#'] = '';
        row[headers.cabecalhoPedido] = '';
        row[headers.cabecalhoData] = '';
        row[headers.cabecalhoOficina] = '';
        row[headers.cabecalhoCnpj] = '';
        row[headers.cabecalhoVeiculo] = '';

        if (payment_type !== 2)
            row[headers.cabecalhoValorPedido] = numberToReal(this.valor_pedido_total);

        if (payment_type === 1)
            row[headers.cabecalhoCustoComissao] = numberToReal(this.custo_comissao_total);

        if (payment_type > 2)
            row[headers.cabecalhoCustoPedido] = numberToReal(this.custo_pelo_pedido_total);

        if (tem_cashback)
            row[headers.cabecalhoCustoCashback] = numberToReal(this.repasse_total);

        return row;
    }

    imprimirGetHeaders() {
        const cabecalhoCotacaoPedido = (this.payment_type !== 2) ? 'part-manager.relatorio-financeiro.lista.cabecalho.pedido' : 'part-manager.relatorio-financeiro.lista.cabecalho.cotacao';

        let cabecalhoPedido = this.globals.translate(cabecalhoCotacaoPedido).toString().removeAccent();
        let cabecalhoData = this.globals.translate('part-manager.relatorio-financeiro.lista.cabecalho.data').toString().removeAccent();
        let cabecalhoOficina = this.globals.translate('part-manager.relatorio-financeiro.lista.cabecalho.oficina').toString().removeAccent();
        let cabecalhoCnpj = this.globals.translate('part-manager.relatorio-financeiro.lista.cabecalho.cnpj').toString().removeAccent();
        let cabecalhoVeiculo = this.globals.translate('part-manager.relatorio-financeiro.lista.cabecalho.veiculo').toString().removeAccent();
        let cabecalhoValorPedido = this.globals.translate('part-manager.relatorio-financeiro.lista.cabecalho.valor-pedido').toString().removeAccent();
        let cabecalhoCustoComissao = this.globals.translate('part-manager.relatorio-financeiro.lista.cabecalho.custo-comissao').toString().removeAccent();
        let cabecalhoCustoPedido = this.globals.translate('part-manager.relatorio-financeiro.lista.cabecalho.custo-pedido').toString().removeAccent();
        let cabecalhoCustoCashback = this.globals.translate('part-manager.relatorio-financeiro.lista.cabecalho.custo-cashback.titulo').toString().removeAccent();

        return { cabecalhoPedido, cabecalhoData, cabecalhoOficina, cabecalhoCnpj, cabecalhoVeiculo, cabecalhoValorPedido, cabecalhoCustoComissao, cabecalhoCustoPedido, cabecalhoCustoCashback };
    }

    selecionarMes($event) {
        this.mes_selecionado = $($event.target).val();
        this.periodo_selecionado = $($event.target).find("option:selected").text();
        this.carregarRelatorio(0);
    }

    setTotals = function () {
        this.valor_pedido_total = 0;
        this.custo_pelo_pedido_total = 0;
        this.custo_comissao_total = 0;
        this.repasse_total = 0;
        this.total_oportunidades = 0;

        var tem_cashback = false;

        this.lista_participando.forEach(item => {
            item.tem_cashback = (item.valor_pedido > item.valor_pedido_desconto_original);
            item.diferenca = item.valor_pedido - item.valor_pedido_desconto_original;
            item.repasse = this.calcularRepasse(item);

            item.lucro_extra = item.diferenca - item.repasse;

            this.valor_pedido_total += item.valor_pedido;
            this.custo_pelo_pedido_total += item.custo_pelo_pedido;
            this.custo_comissao_total += item.custo_comissao;
            this.repasse_total += item.repasse;
            this.total_oportunidades++;

            if (item.tem_cashback)
                tem_cashback = true;

            if (item.taxa_comissao_original > 0 && item.taxa_comissao_sem_rebate !== item.taxa_comissao_original)
                item.exibir_taxa_comissao = true;
        });

        this.tem_cashback = tem_cashback;

        switch (this.payment_type) {
            case 1:
                this.custo_total = this.mensalidade + this.custo_comissao_total + this.repasse_total;
                break;
            case 2:
                this.custo_total = this.mensalidade + (this.total_oportunidades * this.custo_por_cotacao) + this.repasse_total;
                break;
            case 3:
                this.custo_total = this.mensalidade + this.custo_pelo_pedido_total + this.repasse_total;
                break;
            default:
        }

        this.custo_total -= this.reembolso;
    };

    modal_notas_fiscais_abrir(orderId: number) {
        const pedido = this.lista_participando.find(item => item.order_id === orderId);

        let nfs = pedido.notas_fiscais.filter(nf => nf.url.endsWith(".pdf"));
        let notasFiscais: NotaFiscal[] = nfs.map((nf) => { return { numero: nf.numero, url: nf.url } });
        let notas_fiscais: PedidoNotaFiscal = { quotation_id: orderId, can_upload_nf: false, notasFiscais };

        this.pedido_notas = notas_fiscais

        let options: ModalOptions = { backdrop: 'static', class: 'gray modal-lg', keyboard: false };
        this.modalNFRef = this.modalService.show(this.templateNotasFiscais, options);
    }

    modal_notas_fiscais_fechar() {
        this.modalNFRef.hide();
    }

    modal_notas_fiscais_onError(error: any) {
        this.notificationService.showErrorToastr(this.globals.translate('part-manager.relatorio-financeiro.mensagens.erro.carregamento-nota-fiscal.corpo'));
    }
}
