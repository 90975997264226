<div class="relatorio-financeiro" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [hasBackButton]="false"
        [title]="((payment_type === 1 ? 'part-manager.relatorio-financeiro.titulo.comissoes' : (payment_type === 2 ? 'part-manager.relatorio-financeiro.titulo.oportunidades' : 'part-manager.relatorio-financeiro.titulo.pedidos')) | translate) + ' ' + periodo_selecionado"
        [subtitle]="((payment_type === 1 ? 'part-manager.relatorio-financeiro.subtitulo.comissoes' : (payment_type === 2 ? 'part-manager.relatorio-financeiro.subtitulo.oportunidades' : 'part-manager.relatorio-financeiro.subtitulo.pedidos')) | translate)"
    >
        <div class="select-month float-end no-print">
            @if (lista_participando.length > 0) {
                <button class="btn btn-light imprimir" (click)="exportarCSV()"><i class="fa fa-file-excel-o"></i>&nbsp;{{ 'part-manager.relatorio-financeiro.botao.gerar-csv' | translate}}</button>
            }
            <button class="btn btn-light imprimir" (click)="imprimir($event)"><i class="fa fa-print"></i>&nbsp;{{ 'part-manager.relatorio-financeiro.botao.imprimir' | translate}}</button>
            <select class="form-select" (change)="selecionarMes($event)">
                @for (mes of meses; track mes) {
                    <option value="{{mes.ano_mes}}">{{mes.nome}}</option>
                }
            </select>
        </div>
    </app-header>

    <iframe src="" style=" width: 100%; height: 0px; border: none;" id="printView"></iframe>
    <div class="card card-default relatorio">
        <div class="card-body">
            <ng-scrollbar>
                @if (lista_participando.length <= 0) {
                    <div class="vazio">{{ (payment_type == 1 ? 'part-manager.relatorio-financeiro.vazio.comissoes' : (payment_type == 2 ? 'part-manager.relatorio-financeiro.vazio.oportunidades' : 'part-manager.relatorio-financeiro.vazio.pedidos')) | translate}}</div>
                } @else {
                    <div class="table-responsive b0">
                        <table class="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th style="width:30px;"><strong>#</strong></th>
                                    <th><strong>{{ (payment_type !== 2 ? 'part-manager.relatorio-financeiro.lista.cabecalho.pedido' : 'part-manager.relatorio-financeiro.lista.cabecalho.cotacao') | translate}}</strong></th>
                                    <th><strong>{{ 'part-manager.relatorio-financeiro.lista.cabecalho.data' | translate}}</strong></th>
                                    <th><strong>{{ 'part-manager.relatorio-financeiro.lista.cabecalho.oficina' | translate}}</strong></th>
                                    <th><strong>{{ 'part-manager.relatorio-financeiro.lista.cabecalho.cnpj' | translate}}</strong></th>
                                    <th><strong>{{ 'part-manager.relatorio-financeiro.lista.cabecalho.veiculo' | translate}}</strong></th>
                                    @if (payment_type !== 2) {
                                        <th style="min-width: 110px;"><strong>{{ 'part-manager.relatorio-financeiro.lista.cabecalho.valor-pedido' | translate}}</strong></th>
                                    }
                                    @if (payment_type === 1) {
                                        <th><strong>{{ 'part-manager.relatorio-financeiro.lista.cabecalho.custo-comissao' | translate}} <small>({{custo_por_cotacao}}%)</small></strong></th>
                                    }
                                    @if (payment_type > 2) {
                                        <th><strong>{{ 'part-manager.relatorio-financeiro.lista.cabecalho.custo-pedido' | translate}}</strong></th>
                                    }
                                    @if (tem_cashback) {
                                        <th><strong>{{ 'part-manager.relatorio-financeiro.lista.cabecalho.custo-cashback.titulo' | translate}}</strong></th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                @for (item of lista_participando; track item; let i = $index) {
                                    <tr>
                                        <td>{{i + 1}}</td>
                                        <td>
                                            @if (payment_type !== 2) {
                                                <div>
                                                    @if (item.tem_nota_fiscal) {
                                                        <span class="hover-link" (click)="modal_notas_fiscais_abrir(item.order_id)" tooltip="{{ 'part-manager.relatorio-financeiro.lista.sem-nf.tooltip-ver' | translate}}">{{item.order_id}}</span>
                                                    } @else {
                                                        <span class="text-danger" tooltip="{{ 'part-manager.relatorio-financeiro.lista.sem-nf.tooltip-pedido' | translate}}: {{item.order_id}}">{{ 'part-manager.relatorio-financeiro.lista.sem-nf.label' | translate}}</span>
                                                    }
                                                </div>
                                            }
                                            @if (payment_type === 2) {
                                                <div>
                                                    {{item.quotation_id}}
                                                </div>
                                            }
                                        </td>
                                        <td>{{item.data_formatada}}</td>
                                        <td>{{item.oficina}}</td>
                                        <td>{{item.cnpj_formatado}}</td>
                                        @if (payment_type !== 2) {
                                            <td class="veiculo hover-link" (click)="exibirPecas(item)">
                                                <span class="truncate"><em class="fa fa-plus float-start"></em> {{item.veiculo}}</span>
                                                @if (item.contestacao > 0) {
                                                    <em [ngClass]="{ 'text-warning': item.contestacao === 1, 'text-success': item.contestacao === 2 }" class="fa fa-exclamation-triangle contestacao"></em>
                                                }
                                            </td>
                                            <td>{{item.valor_pedido_formatado}}</td>
                                        }
                                        @if (payment_type === 2) {
                                            <td><span class="truncate">{{item.veiculo}}</span></td>
                                        }
                                        @if (payment_type === 1) {
                                            <td class="linha-comissao">
                                                <div class="tem-cashback">
                                                    {{item.custo_comissao_formatado}}
                                                </div>
                                                @if (item.tem_rebate_extra || item.exibir_taxa_comissao) {
                                                    <ng-template #tipContent>
                                                        <div class="comission-data">
                                                            @if (item.exibir_taxa_comissao) {
                                                                <span>{{ 'part-manager.relatorio-financeiro.lista.detalhes.comissao.customizada' | translate: { value: (item.taxa_comissao_sem_rebate | number) } }}</span>
                                                            }

                                                            @if (item.tem_rebate_extra) {
                                                                <span>{{ 'part-manager.relatorio-financeiro.lista.detalhes.comissao.acrescimo-cashback' | translate: { value: (item.comissao_extra | number) } }}</span>
                                                                <span>{{ 'part-manager.relatorio-financeiro.lista.detalhes.comissao.total' | translate: { value: (item.taxa_comissao | number) } }}</span>
                                                            }
                                                        </div>
                                                    </ng-template>
                                                    <i class="fa fa-info-circle" [ngbTooltip]="tipContent"></i>
                                                }
                                            </td>
                                        }
                                        @if (payment_type > 2) {
                                            <td>{{item.custo_pelo_pedido_formatado}}</td>
                                        }
                                        @if (tem_cashback) {
                                            @if (item.repasse > 0) {
                                                <td class="tem-cashback" (click)="abrirMaisDetalhes(item)" tooltip="{{ 'part-manager.relatorio-financeiro.lista.cabecalho.custo-cashback.mais-detalhes' | translate }}">
                                                    {{item.repasse | currency : 'R$'}}
                                                    <em class="fa fa-plus float-end"></em>
                                                </td>
                                            } @else {
                                                <td>
                                                    {{item.repasse | currency : 'R$'}}
                                                </td>
                                            }
                                        }
                                    </tr>
                                }
                            </tbody>
                            @if (payment_type !== 2) {
                                <tfoot>
                                    <tr style="font-weight:bold">
                                        <td></td>
                                        <td colspan="5" class="text-end only-print"></td>
                                        <td colspan="5" class="text-end no-print">{{ 'part-manager.relatorio-financeiro.lista.total' | translate}}:</td>
                                        <td>R$ {{valor_pedido_total | currency : ' '}}</td>
                                        @if (payment_type === 1) {
                                            <td>R$ {{custo_comissao_total | currency : ' ' }}</td>
                                        }
                                        @if (payment_type > 2) {
                                            <td>R$ {{custo_pelo_pedido_total | currency : ' ' }}</td>
                                        }
                                        @if (tem_cashback) {
                                            <td>{{repasse_total | currency:"R$ "}}</td>
                                        }
                                    </tr>
                                </tfoot>
                            }
                        </table>
                    </div>
                }
                <div class="sumario col-md-4">
                    <span class="title">{{ 'part-manager.relatorio-financeiro.sumario.titulo' | translate}}</span>
                    <div class="col-md-12">
                        <label>{{ 'part-manager.relatorio-financeiro.sumario.mensalidade' | translate}}:</label>
                        <input type="text" class="form-control col-md-4" disabled placeholder="{{mensalidade | currency : 'R$ '}}" />
                    </div>
                    @switch (payment_type) {
                        @case (1) {
                            <div class="col-md-12">
                                <label>{{ 'part-manager.relatorio-financeiro.sumario.custo-comissao' | translate}} <small style="font-weight: normal;">({{custo_por_cotacao}}%)</small>:</label>
                                <input type="text" class="form-control" disabled placeholder="{{custo_comissao_total | currency : 'R$ '}}" />
                            </div>
                        }
                        @case (2) {
                            <div class="col-md-12">
                                <label>{{ 'part-manager.relatorio-financeiro.sumario.oportunidades' | translate}}:</label>
                                <input type="text" class="form-control" disabled placeholder="{{ total_oportunidades }}" /> <!--total_oportunidades-->
                            </div>
                            <div class="col-md-12">
                                <label>{{ 'part-manager.relatorio-financeiro.sumario.custo-cotacao' | translate}}:</label>
                                <input type="text" class="form-control" disabled placeholder="{{custo_por_cotacao | currency : 'R$ '}}" />
                            </div>
                        }
                        @case (3) {
                            <div class="col-md-12">
                                <label>{{ 'part-manager.relatorio-financeiro.sumario.pedidos' | translate}}:</label>
                                <input type="text" class="form-control" disabled placeholder="{{custo_pelo_pedido_total | currency : 'R$ '}}" />
                            </div>
                        }
                    }
                    @if (tem_cashback) {
                        <div class="col-md-12">
                            <label>{{ 'part-manager.relatorio-financeiro.sumario.cashback' | translate}}:</label>
                            <input type="text" class="form-control" disabled placeholder="{{repasse_total | currency : 'R$ '}}" />
                        </div>
                    }
                    @if (reembolso > 0) {
                        <div class="col-md-12">
                            <label class="text-danger">{{ 'part-manager.relatorio-financeiro.sumario.reembolso' | translate}}:</label>
                            <input type="text" class="form-control reembolso" disabled placeholder="-{{reembolso | currency : 'R$ '}}" />
                        </div>
                    }
                    <div class="col-md-12">
                        <label>{{ 'part-manager.relatorio-financeiro.sumario.total' | translate}}:</label>
                        <input type="text" class="form-control" disabled placeholder="{{custo_total | currency : 'R$ '}}" />
                    </div>
                </div>
            </ng-scrollbar>
        </div>
    </div>
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>

<ng-template #templateNotasFiscais>
    <div class="modal-header lista-notas-fiscais-header">
        <h4 class="modal-title">{{ 'seller.dashboard.detalhes.notas-fiscais.titulo' | translate}}{{pedido_notas?.quotation_id}}</h4>
        <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close" (click)="modal_notas_fiscais_fechar();"></button>
    </div>
    <div class="col-md-12 mt-sm pr0 lista-notas-fiscais">
        <app-nota-fiscal #appNFs [pedido]="pedido_notas" [pdf_viewer_class]="'relatorio-financeiro'" [is_central]="false" [is_seller]="false" [showTransportadora]="false"></app-nota-fiscal>
    </div>
    <div class="modal-footer">
        <button id="closeModal" type="button" class="btn btn-light" (click)="modal_notas_fiscais_fechar();">{{ 'elemento.generico-botao.fechar' | translate}}</button>
    </div>
</ng-template>
