import { Component, OnInit } from '@angular/core';
import { formatPercent } from '@angular/common';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { Globals } from '../../_models/globals';
import { User } from '../../_models/user';

import { AccountService } from '../../account/account.service';
import { BaseService } from '../../_services/base.service';
import { NotificationService } from '../../_services/notification.service';
import { PartManagerConfigurarRebateService } from './configurar-rebate.service';
import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { RebateSettings, RebateSettingsRepairer } from './_models/rebateSettings';

declare const $: any;
declare const isEmpty: any;
declare const sort_by: any;

@Component({ selector: 'app-configurar-rebate', templateUrl: './configurar-rebate.component.html', styleUrls: ['./configurar-rebate.component.scss'] })
export class PartManagerConfigurarRebateComponent implements OnInit
{
    rebate_inativo: boolean;
    visivel: boolean = false;
    oficinas: RebateSettingsRepairer[];
    rebateForm: UntypedFormGroup;
    rebateOficinaForm: UntypedFormGroup;
    usuarioLocal: User;

    constructor(
        private accountService: AccountService,
        private baseService: BaseService,
        private notificationService: NotificationService,
        private rebateService: PartManagerConfigurarRebateService,
        private globals: Globals,
        private fb: UntypedFormBuilder,
        private ngxLoader: NgxUiLoaderService) {
        this.usuarioLocal = this.baseService.usuarioLocal;

        if (isEmpty(this.usuarioLocal))
        {
            this.accountService.logout();
            return;
        }
    }

    ngOnInit(): void {
        this.rebate_inativo = true;
        this.oficinas = [];

        this.rebateForm = this.fb.group({ rebate: [1, [Validators.max(20), Validators.min(1)]] });
        this.rebateOficinaForm = this.fb.group({ holding_id: [0, [Validators.required]], rebate: [1, [Validators.max(20), Validators.min(1)]] });
        this.carregarTela(0);
    }

    carregarTela(nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.rebateService.carregarDadosHolding().subscribe({
            next: (response: ApiResponseModel<RebateSettings>) => {
                if (!isEmpty(response) && response.success) {
                    let rebate = response.result.rebate;
                    this.rebateForm.patchValue({ rebate: rebate });
                    this.rebateForm.markAsDirty();
                    this.rebate_inativo = isEmpty(rebate) || rebate < 1;

                    this.oficinas = response.result.oficinas;

                    if (this.oficinasNaoSelecionadas.length > 0) {
                        this.rebateOficinaForm.patchValue({ holding_id: this.oficinasNaoSelecionadas[0].holdingId });
                        this.rebateOficinaForm.markAsDirty();
                    }
                }

                this.visivel = true;
                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-rebate.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarTela(++nAttempts);
            }
        });
    }

    async incentivo_AdicionarOficina(nAttempts: number, ignorarConfirm: boolean) {
        if (this.rebateOficinaForm.dirty && this.rebateOficinaForm.valid) {
            let oficina = this.oficinas.find(o => o.holdingId === parseInt(this.rebateOficinaForm.value.holding_id));

            if (isEmpty(oficina))
                return;

            let rebate = this.rebateOficinaForm.value.rebate;

            if (rebate > 1 && !ignorarConfirm) {
                let increase = formatPercent(((rebate - 1) / 100), 'pt-BR', '1.1-2');
                let confirmMessage = this.globals.translate('part-manager.configurar-rebate.area-incentivo.mensagens.confirm').replace('x%', `${increase}`);
                confirmMessage = confirmMessage.replace('{system}', this.globals.translate('geral.sistema'));

                if (!(await this.notificationService.showConfirmationDialog(confirmMessage, null, false)))
                    return;
            }

            oficina.rebate = rebate;

            this.rebateService.adicionarIncentivo(oficina.holdingId, rebate).subscribe({
                next: (response: ApiResponseModel<boolean>) => {
                    if (!isEmpty(response) && response.success) {
                        const newHoldingId = $('.oficinas').val();
                        this.rebateOficinaForm.patchValue({ rebate: 1, holding_id: newHoldingId });
                        this.notificationService.showSuccessToastr(this.globals.translate('part-manager.configurar-rebate.area-incentivo.mensagens.sucesso.adicionar-incentivo.corpo'));
                    }
                },
                error: error => {
                    nAttempts = nAttempts || 1;
                    console.log(error, nAttempts);

                    if (nAttempts >= 5) {
                        this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-rebate.area-incentivo.mensagens.erro.adicionar-incentivo.corpo'));
                        this.carregarTela(0);
                        return;
                    }

                    this.incentivo_AdicionarOficina(++nAttempts, true);
                }
            });
        }
    }

    async incentivo_RemoverOficina(holding_id_repairer: number, nAttempts: number, ignoreConfirmation: boolean = false) {
        if (!ignoreConfirmation && !(await this.notificationService.showConfirmationDialog('part-manager.configurar-rebate.area-incentivo.mensagens.confirm-remove')))
            return;

        let oficina = this.oficinas.find(o => o.holdingId === holding_id_repairer);

        if (isEmpty(oficina))
            return;

        oficina.rebate = null;

        this.rebateService.removerIncentivo(holding_id_repairer).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (!isEmpty(response) && response.success)
                    this.notificationService.showSuccessToastr(this.globals.translate('part-manager.configurar-rebate.area-incentivo.mensagens.sucesso.remover-incentivo.corpo'));
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-rebate.area-incentivo.mensagens.erro.remover-incentivo.corpo'));
                    this.carregarTela(0);
                    return;
                }

                this.incentivo_RemoverOficina(holding_id_repairer, ++nAttempts, true);
            }
        });
    }

    async salvar(nAttempts: number, ignorarConfirm: boolean) {
        if (this.rebateForm.dirty && this.rebateForm.valid) {
            let rebate = this.rebateForm.value.rebate;

            if (rebate > 1 && !ignorarConfirm) {
                let increase = formatPercent(((rebate - 1) / 100), 'pt-BR', '1.1-2');
                let confirmMessage = this.globals.translate('part-manager.configurar-rebate.mensagens.confirm').replace('x%', `${increase}`);
                confirmMessage = confirmMessage.replace('{system}', this.globals.translate('geral.sistema'));

                if (!(await this.notificationService.showConfirmationDialog(confirmMessage, null, false)))
                    return;
            }

            this.ngxLoader.startLoader('loader-principal');

            this.rebateService.salvar(rebate).subscribe({
                next: (response: ApiResponseModel<boolean>) => {
                    if (!isEmpty(response) && response.success)
                        this.notificationService.showSuccessToastr(this.globals.translate('part-manager.configurar-rebate.mensagens.sucesso.salvar.corpo'));

                    this.ngxLoader.stopLoader('loader-principal');
                },
                error: error => {
                    nAttempts = nAttempts || 1;
                    console.log(error, nAttempts);

                    if (nAttempts >= 5) {
                        this.ngxLoader.stopLoader('loader-principal');
                        this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-rebate.mensagens.erro.salvar.corpo'));
                        return;
                    }

                    this.salvar(++nAttempts, true);
                }
            });
        }
    }


    get oficinasNaoSelecionadas() {
        if (isEmpty(this.oficinas))
            return [];

        return this.oficinas.filter(o => isEmpty(o.rebate) || o.rebate < 1).sort(sort_by({ name: 'fantasy_name' }));
    }

    get oficinasSelecionadas() {
        if (isEmpty(this.oficinas))
            return [];

        return this.oficinas.filter(o => !isEmpty(o.rebate) && o.rebate >= 1).sort(sort_by({ name: 'fantasy_name' }));
    }
}
